<!--  -->
<template>
  <div class="">
    <!-- 增加三级任务与编辑分类，增加分类 -->
    <!-- type1=增加分类 3=编辑分类 2=增加任务 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      style="min-width: 660px"
      width="660px"
    >
      <el-form :model="form" v-if="type == 2">
        <el-form-item label="任务名称" :label-width="formLabelWidth">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="输入任务名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="执行者" :label-width="formLabelWidth">
          <el-select
            @change="selectChange"
            v-model="form.users"
            multiple
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参与者" :label-width="formLabelWidth">
          <el-select
            v-model="form.participant"
            multiple
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开始时间" :label-width="formLabelWidth">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.add_time"
                type="datetime"
                placeholder="开始时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间" :label-width="formLabelWidth">
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.del_time"
                type="datetime"
                placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="优先级" :label-width="formLabelWidth">
          <el-select
            v-model="form.priority"
            style="width: 100%"
            placeholder="选择优先级"
            clearable
          >
            <el-option
              v-for="item in section"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-button type="warning" size="small" @click="changeTask"
          >设置任务占比</el-button
        >
      </el-form>
      <el-form :model="form" v-if="type == 3">
        <el-form-item label="选择人员">
          <el-select
            v-model="form.users"
            multiple
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="输入分类名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form :model="form" v-if="type == 1">
        <el-form-item label="选择人员">
          <el-select
            v-model="form.users"
            multiple
            style="width: 100%"
            placeholder="请选择添加人员"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称">
          <el-input
            v-model="form.name"
            autocomplete="off"
            placeholder="输入分类名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer" v-if="this.type == 1">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="getAddTaskStair()">提 交</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-if="this.type == 2">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="getAddtask()">确 定</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-if="this.type == 3">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="getEditTaskStair()">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 设置增加任务占比 -->
    <el-dialog width="660px" title="设置任务占比" :visible.sync="innerVisible">
      <div class="circle">
        <div
          v-for="(item, index) in form.percentage"
          :key="index"
          class="circle-info"
        >
          <avue-slider
            v-model="item.percentage"
            :max="100"
            :min="0"
            show-input
          ></avue-slider>
          <span style="margin: 10px 0">{{ item.name }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { number } from "echarts";
import { taskmodelAddTaskr, addTaskStair, editTaskStair } from "@/api/taskall";

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    addId: Object,
    type: Number,
    options: Array,
    section: Array,
  },
  data() {
    //这里存放数据
    return {
      formLabelWidth: "100px",
      title: "分类信息",
      dialogFormVisible: true,
      form: {
        name: "",
        users: [],
        pid: "",
        priority: "",
        percentage: [],
      },
      userArr: [],
      userNumber: [],
      innerVisible: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //添加任务
    async getAddtask() {
      let info = await taskmodelAddTaskr(this.form);
      this.judge(info);
    },
    //添加分类
    async getAddTaskStair() {
      console.log(this.form);
      let info = await addTaskStair(this.form);
      this.judge(info);
    },
    //修改分类
    async getEditTaskStair() {
      let info = await editTaskStair(this.form);
      this.judge(info);
    },
    //判断接口返回状态
    judge(info) {
      if (info.data.code != 0) {
        this.$message({
          message: info.data.message,
          type: "warning",
        });
      } else if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: "success",
        });
        this.dialogFormVisible = false;
        this.$parent.getListTaskStairB();
        this.userClose();
      }
    },
    userClose() {
      this.$parent.openAdd = false;
    },
    selectChange(info) {
      console.log(this.form);
      let userNumber = [];
      info.forEach((element) => {
        let name = this.options.find((item) => item.id == element);
        name.percentage = 0;
        this.userNumber.push(name.percentage);
        userNumber.push(name);
      });
      this.form.percentage = userNumber;
    },
    //设置任务占比
    changeTask() {
      this.innerVisible = true;
    },
    // 根据组件type选择不同状态
    setType(type) {
      if (type == 1) {
        this.title = "分类信息";
        this.form.pid = this.$route.query.id;
      }
      if (type == 2) {
        this.title = "任务信息";
        let form = {
          name: "",
          users: [],
          pid: this.addId.pid,
          add_time: "",
          del_time: "",
          percentage: [],
          participant: [],
        };
        this.form = form;
      }
      if (type == 3) {
        this.title = "编辑分类";
        this.form.id = this.addId.id;
        this.form.name = this.addId.name;
        this.form.users = this.addId.users;
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {
    this.setType(this.type);
  }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {
    console.log("销毁");
  }, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
</style>