import { post } from '@/utils/request'
//聊天列表
export function chatList(data) {
    return post('admin/taskchat/listChat', data)
}
// 发信息
export function chatAddChat(data) {
    return post('admin/taskchat/addChat', data)
}
//消息撤回
export function withdraw(data) {
    return post('admin/taskchat/withdraw', data)
}
//消息修改
export function editChat(data) {
    return post('admin/taskchat/editChat', data)
}
export function getms() {
    return post('admin/user/ceshi')
}



