<template>
  <div>
    <div id="chartPie" class="pie-wrap"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
// require("echarts/theme/macarons"); //引入主题,可以不引入，有默认的
export default {
  props: {
    info: Array,
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.setInfo();
    this.$nextTick(() => {
      this.drawPieChart();
    });
  },
  methods: {
    setInfo() {
      console.log(this.info);
      this.info.forEach((item) => {
        this.list.push({ value: item.percentage, name: item.name });
      });
    },
    drawPieChart() {
      this.chartPie = echarts.init(
        document.getElementById("chartPie"),
        "macarons"
      );
      this.chartPie.setOption({
        //显示在上面的文字
        tooltip: {
          trigger: "item",
          // formatter: "{a}<br/>{b}: <br/>{c}({d}%)",
          // 其中 {a}指向name名称（访问来源）
        },
        // series: [
        //   {
        //     name: "访问来源",
        //     type: "pie",
        //     //圆圈的粗细
        //     radius: ["50%", "80%"],
        //     //圆圈的位置
        //     center: ["50%", "50%"],
        //     data: [
        //       {
        //         value: 10,
        //         name: "直接访问",
        //       },
        //       {
        //         value: 20,
        //         name: "邮件营销",
        //       },
        //       {
        //         value: 40,
        //         name: "联盟广告",
        //       },
        //       {
        //         value: 60,
        //         name: "视频广告",
        //       },
        //       {
        //         value: 30,
        //         name: "搜索引擎",
        //       },
        //     ],
        //     //动画持续时间：2秒
        //     animationDuration: 2000,
        //     //控制是否显示指向文字的,默认为true
        //     label: {
        //       show: false,
        //       position: "center",
        //       //   以下代码可以代表指向小文字的
        //     //   show: true,
        //     //   formatter: "{b} : {c} ({d}%)",
        //     //   textStyle: {
        //     //     color: "#333",
        //     //     fontSize: 14,
        //     //   },
        //     },
        //   },
        // ],
        series: [
          {
            label: {
              formatter: "{b}: {c}",
            },
            name: "任务占比",
            type: "pie",
            radius: ["40%", "70%"],
            itemStyle: {
              borderRadius: 10,
              borderColor: "#FFFFFF",
              borderWidth: 2,
            },
            data: this.list,
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: "rgba(0, 0, 0, 0.5)",
            //   },
            // },
          },
        ],
      });
    },
  },
};
</script>

<style  scoped>
.pie-wrap {
  width: 600px;
  height: 400px;
}
</style>

