<!-- 任务列表 -->
<template>
  <div class="task-all">
    <div class="task-info">
      <div class="info-card" v-for="item in table" :key="item.id">
        <div class="info-title" @click="editClass(item)">
          {{ item.name }}
        </div>
        <div class="task-card-sc">
          <div
            class="task-card"
            v-for="item2 in item.sub"
            :key="item2.id"
            @click="getinfo(item2.id, item.id)"
          >
            <div class="options">
              <div @click.stop="checkbox">
                <el-checkbox
                  v-model="item2.state"
                  :true-label="2"
                  :false-label="1"
                >
                </el-checkbox>
              </div>
            </div>
            <div class="task-title">
              {{ item2.name }}
              <div class="info-time-all">
                <div
                  class="info-time"
                  v-if="item2.time_state == 1"
                  style="background-color: #409eff"
                >
                  {{ item2.del_time }}
                </div>
                <div
                  class="info-time"
                  v-if="item2.time_state == 2"
                  style="background-color: #e6a23c"
                >
                  {{ item2.del_time }}
                </div>
                <div class="info-time" v-if="item2.time_state == 3">
                  {{ item2.del_time }}
                </div>
              </div>
              <div class="circle-mag">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="任务占比"
                  placement="bottom"
                >
                  <div class="item-back">
                    <div class="item-back-1 el-icon-bangzhu"></div>
                    <el-progress
                      :stroke-width="2"
                      type="circle"
                      :width="40"
                      :percentage="item2.percentage"
                      color="#f56c6c"
                    ></el-progress>
                  </div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="时间进度"
                  placement="bottom"
                >
                  <div class="item-back">
                    <div class="item-back-1 el-icon-time"></div>
                    <el-progress
                      :stroke-width="2"
                      type="circle"
                      :width="40"
                      :percentage="item2.time_formerly"
                      color="#e6a23c"
                    ></el-progress>
                  </div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="任务进度"
                  placement="bottom"
                >
                  <div class="item-back">
                    <div class="item-back-1 el-icon-odometer"></div>
                    <el-progress
                      :stroke-width="2"
                      type="circle"
                      :width="40"
                      :percentage="item2.stage"
                      color="#5cb87a"
                    ></el-progress>
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="head-info">
              <div class="head-img">
                <img :src="item2.img" alt="" width="30" class="image" />
              </div>
              <i class="el-icon-setting" @click.stop="setting(item2)"></i>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <el-button
            style="width: 100%"
            icon="el-icon-plus"
            @click="addtask(item)"
          ></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    table: Array,
  },
  data() {
    //这里存放数据
    return {
      time_formerly: 100,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    editClass(item) {
      this.$parent.editClass(item)
    },
    //打开任务详情
    getinfo(item2, item) {
      this.$parent.idInfo = { id: item2, ids: item }
      this.$parent.openTask = true
    },
    checkbox() {
      this.$parent.checkbox()
    },
    setting(item2) {
      this.$parent.settingInfoId = { pid: item2.id }
      this.$parent.openLev = true
    },
    addtask(item) {
      this.$parent.addtask(item)
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.task-all {
  width: 100%;
  height: calc(100% - 20px);
  overflow: auto;
}
.task-info {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
}
.info-card {
  width: 300px;
  margin-left: 20px;
  flex-shrink: 0;
}
.info-title {
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #262626;
  padding: 8px 0;
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: pointer;
}
.task-card {
  position: relative;
  width: 100%;
  white-space: normal;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  padding: 14px 16px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 10px;
}
.image {
  margin: 0 8px 0 5px;
  padding: 2px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eee;
  vertical-align: middle;
}
.options,
.head-img {
  position: relative;
  top: -2px;
}
.task-title {
  word-wrap: break-word;
  flex: 1;
}
.circle {
  width: 100%;
  height: 200px;
  overflow: auto;
}
.circle-info {
  width: 40%;
  float: left;
  margin-right: 10px;
  margin-left: 15px;
}
.info-log {
  width: 100%;
  display: flex;
  border-top: 1px solid #e5e5e5;
}
.info-left {
  width: 30%;
  padding-left: 10px;
  padding-right: 20px;
  border-right: 1px solid #e5e5e5;
}
.info-right {
  flex: 1;
}
.info-pad >>> .el-dialog__body {
  padding: 10px !important;
}
.info-time-all {
  display: flex;
}
.info-time {
  color: #fff;
  background-color: #e62412;
  border-radius: 2px;
  margin: 2px 6px 0 0;
  font-size: 12px;
  padding: 2px 6px;
}
.el-icon-setting {
  margin: 0 8px 0 5px;
  padding: 2px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  box-sizing: border-box;
  text-align: center;
}
.el-icon-setting:hover {
  color: #409eff;
}
.circle-mag {
  margin-top: 10px;
}
.head-info {
  flex-direction: column;
  display: flex;
  align-items: center;
}
.task-card-sc {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.item-back-1 {
  width: 100%;
  height: 40px;
  position: absolute;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  color: #e5e5e5;
}
* >>> .el-progress__text {
  font-weight: bold;
  position: relative;
}
.item-back {
  position: relative;
  display: inline-block;
  /* background: red; */
  z-index: 999;
}
</style>
