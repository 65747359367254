<!--  -->
<template>
  <div class="home-index">
    <Breadcrumb :getNav="getNav" :info="query.name"></Breadcrumb>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-button @click="addClass">添加分类</el-button>
      </el-col>
      <el-col :span="20">
        <!-- 分页 -->
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :pager-count="15"
          layout="total, prev, pager, next"
          :page-size="this.pageInfo.page_num"
          background
          :total="exp.num"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <!-- 任务列表 -->
    <TaskInfo :table="table"> </TaskInfo>

    <!-- type1=增加分类 2=增加任务 3=编辑分类  -->
    <TaskAdd
      :options="options"
      :section="section"
      :addId="addId"
      :type="type"
      v-if="openAdd"
    >
    </TaskAdd>

    <!-- 任务详情 -->
    <TaskDia
      ref="TaskDia"
      v-if="openTask"
      :idInfo="idInfo"
      :options="options"
      :section="section"
    >
    </TaskDia>

    <!-- 任务分段 -->
    <TaskLev
      ref="TaskLev"
      v-if="openLev"
      :settingInfoId="settingInfoId"
    ></TaskLev>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { Employee, listTaskStairB, prioritySection } from "@/api/taskall";
import TaskInfo from "@/components/Task/TaskInfo.vue";
import TaskDia from "@/components/Task/TaskDia.vue";
import TaskLev from "@/components/Task/TaskLev.vue";
import TaskAdd from "@/components/Task/TaskAdd.vue";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { TaskInfo, TaskDia, TaskLev, TaskAdd },
  data() {
    //这里存放数据
    return {
      addId: {
        pid: "",
        id: "",
        name: "",
        users: [],
      },
      taskState: {},
      settingInfoId: "",
      idInfo: {},
      openLev: false,
      openTask: false,
      openAdd: false,
      type: 1,
      emp: {
        id: "",
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      pageInfo: {
        id: "",
        page_code: 1,
        page_num: 4,
      },
      allNumber: 0,
      minInfo: {},
      taskdialog: false,
      name: {
        value: 100,
      },
      section: [],
      checked: false,
      getNav: [{ path: "/taskall", name: "任务中心" }],
      table: [],
      query: {},
      del_time: {},
      options: [],
      form: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //增加事件
    addClass() {
      this.openAdd = true;
      this.type = 1;
      this.emp.id = this.query.id;
      this.getEmployee();
    },
    //增加任务
    async addtask(item) {
      this.openAdd = true;
      this.type = 2;
      console.log(item.id);
      this.addId.pid = item.id;
    },
    //编辑分类
    editClass(form) {
      this.emp.id = this.query.id;
      this.getEmployee();
      this.openAdd = true;
      this.type = 3;
      this.addId.id = form.id;
      this.addId.name = form.name;
      this.addId.users = form.users;
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value);
      this.pageInfo.page_num = value;
      this.getListTaskStairB();
    },
    //分页切换
    pageChange(value) {
      this.pageInfo.page_code = value;
      this.getListTaskStairB();
    },
    edit() {},
    checkbox() {
      return;
    },
    inputNumber(item) {
      console.log(item);
      console.log(this.userArr);
    },
    changeNumber(item) {
      console.log(item);
    },
    //获取人员
    async getEmployee() {
      let info = await Employee(this.emp);
      let section = await prioritySection();
      this.options = info.data.data;
      this.section = section.data.data;
    },
    //获取列表
    async getListTaskStairB() {
      this.pageInfo.id = this.query.id;
      let info = await listTaskStairB(this.pageInfo);
      console.log(info);
      this.table = info.data.data;
      this.exp.count = info.data.exp.count;
      this.exp.num = info.data.exp.num;
      console.log(this.table);
    },
    //根据屏幕判断每一页
    setData() {
      this.query = this.$route.query;
      this.form.pid = this.query.id;
      let win = document.body.clientWidth;
      console.log(win / 320);
      win = win - 260;
      win = win / 320;
      console.log(win.toFixed(0));
      this.pageInfo.page_num = parseInt(win.toFixed(0));
      this.getListTaskStairB();
    },
    //计算任务总分比
    comChange() {
      let info = this.minInfo.percentage;
      let number = 0;
      console.log("info");
      console.log(info);
      info.forEach((item) => {
        number += item.percentage;
      });
      this.allNumber = number;
    },
    //整合任务占比
    taskCum2() {
      let info = this.minInfo.percentage;
      info.forEach((item) => {
        item.percentage = Math.floor((item.percentage / this.allNumber) * 100);
      });
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.setData();
    this.getEmployee();
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
.home-index {
  height: calc(100% - 60px);
}
</style>