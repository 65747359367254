<!--  -->
<template>
  <!-- 任务详情 -->
  <el-dialog
    top="50px"
    class="info-pad"
    title="任务详情"
    :visible.sync="taskdialog"
    @close="userClose()"
    style="min-width: 1100px; padding: 0px"
    width="90%"
  >
    <!-- 编辑信息 -->
    <el-dialog
      width="30%"
      title="编辑信息"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="redactContent.info.content"
        >
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setEditChat(1)">修改</el-button>
      </div>
    </el-dialog>
    <!-- 功能栏 -->
    <div class="info-log">
      <!-- 左侧 -->
      <div class="info-left">
        <el-form :model="form" label-position="left" size="small ">
          <el-form-item label="状态" :label-width="formLabelWidth">
            <div v-if="minInfo.state != 1">
              <el-button
                type="warning"
                size="mini"
                @click="changeState"
                v-if="minInfo.state == 0"
                >点击完成</el-button
              >
              <el-button
                type="success"
                size="mini"
                v-if="minInfo.state == 2"
                disabled
                >已完成</el-button
              >
            </div>
            <el-button type="info" size="mini" v-else @click="openTask"
              >开始任务</el-button
            >
          </el-form-item>
          <el-form-item label="优先级" :label-width="formLabelWidth">
            <el-select
              @change="changePrio"
              v-model="minInfo.priority"
              style="width: 100%"
              placeholder="选择优先级"
              clearable
            >
              <el-option
                v-for="item in section"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务名称" :label-width="formLabelWidth">
            <el-input
              @change="changeName"
              v-model="minInfo.name"
              autocomplete="off"
              placeholder="输入任务名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="负责人" :label-width="formLabelWidth">
            <el-select
              @change="changePrincipal"
              v-model="minInfo.u_id"
              style="width: 100%"
              placeholder="请选择添加人员"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="执行者" :label-width="formLabelWidth">
            <el-select
              size="small"
              @change="selectChange2"
              v-model="minInfo.users"
              multiple
              style="width: 100%"
              placeholder="请选择添加人员"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="参与者" :label-width="formLabelWidth">
            <el-select
              @change="selectChange2"
              v-model="minInfo.participant"
              multiple
              style="width: 100%"
              placeholder="请选择添加人员"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间" :label-width="formLabelWidth">
            <el-date-picker
              disabled
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="minInfo.add_time"
              type="datetime"
              placeholder="开始时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" :label-width="formLabelWidth">
            <el-date-picker
              v-if="minInfo.apply == 1"
              @change="changeTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="minInfo.del_time"
              type="datetime"
              placeholder="结束时间"
            >
            </el-date-picker>
            <el-date-picker
              v-if="minInfo.apply == 2"
              disabled
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="minInfo.del_time"
              type="datetime"
              placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <!-- 右侧 -->
      <div class="info-right">
        <div class="info-right-title">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="确认任务情况" name="3">
              <div class="over-task">
                <div>
                  已查阅:
                  <el-tag
                    style="margin-right: 2px"
                    size="small"
                    v-for="(item, index) in minInfo.examine_b"
                    :key="index"
                    >{{ item }}</el-tag
                  >
                </div>
                <div style="margin-top: 5px">
                  未查阅:
                  <el-tag
                    style="margin-right: 2px"
                    size="small"
                    type="info"
                    v-for="(item, index) in minInfo.examine_a"
                    :key="index"
                    >{{ item }}</el-tag
                  >
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item title="成员任务占比" name="2">
              <div class="info-task-sli" v-if="pie">
                <div
                  v-for="(item, index) in minInfo.percentage"
                  :key="index"
                  class="sli-name"
                >
                  <span>{{ item.name }}</span>
                  <el-slider
                    v-model="item.percentage"
                    show-input
                    @input="comChange"
                  >
                  </el-slider>
                </div>
              </div>
              <div class="pie" v-if="!pie">
                <Pie :info="minInfo.percentage"></Pie>
              </div>
              <el-button @click="changePie" size="small">
                <span v-show="pie"> 切换饼图 </span>
                <span v-show="!pie"> 切换编辑 </span>
              </el-button>

              <el-button size="small" @click="taskChange"
                >提交任务分配</el-button
              >
              <el-button size="small" @click="taskCum"
                >给剩余人员分配</el-button
              >
              <span> {{ allNumber }}% </span>
              <span
                style="font-size: 12px; color: red"
                v-show="allNumber > 100"
              >
                任务分配比例异常
              </span>
            </el-collapse-item>
            <!-- 聊天阶段 -->
            <el-collapse-item title="任务动态" name="1">
              <div class="dynamic" ref="Dynamic">
                <div class="more">
                  <div
                    class="more-text"
                    v-show="chatInfo.length != 0"
                    @click="getList"
                  >
                    查看更多
                  </div>
                </div>
                <div v-for="(item, index) in chatInfo" :key="index">
                  <!-- 聊天阶段 -->
                  <div v-show="item.state == 2" class="card-score-all">
                    <div class="card-score-title">阶段评分:</div>
                    <div
                      class="card-score"
                      v-for="(itemChild, index) in item.sub"
                      :key="index"
                    >
                      <img
                        :src="itemChild.img"
                        alt=""
                        width="30"
                        class="image"
                      />

                      {{ itemChild.name }}
                      {{ itemChild.score }}
                    </div>
                  </div>
                  <!-- 聊天内容 -->
                  <div
                    v-show="item.state == 1 || item.state == 3"
                    :style="{ background: item.state == 3 ? '' : '' }"
                    class="dynamic-info"
                  >
                    <div class="dynamic-left">
                      <img :src="item.u_img" alt="" width="30" class="image" />
                    </div>
                    <div class="dynamic-right">
                      <div class="dynamic-right-top">
                        <div>
                          <span class="text-sty1">
                            {{ item.u_name }}
                          </span>
                          <span class="text-sty" @click="reply('name')"
                            >回复</span
                          >
                          <span class="text-sty" @click="redact(item, index, 1)"
                            >编辑</span
                          >
                          <span class="text-sty" @click="redact(item, index, 2)"
                            >删除</span
                          >
                        </div>
                        <span class="dynamic-time">{{ item.time }}</span>
                      </div>
                      <div class="dynamic-right-bottom">
                        {{ item.content }}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="chatInfo.length == 0">暂无消息记录</div>
              </div>
              <div class="dynamic-text">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="textarea"
                >
                </el-input>
                <div class="dynamic-text-bottom">
                  <div class="text-icon">
                    <i class="el-icon-share"></i>
                  </div>
                  <el-link :underline="false" type="primary" @click="listen()"
                    >发送</el-link
                  >
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Pie from '@/components/Pie.vue'
import { chatAddChat, chatList, editChat, withdraw } from '@/api/chat'
import {
  taskmodelAccomplish,
  taskmodelConfirmTask,
  infoTaskA,
  taskmodelEdit,
  checkTime,
} from '@/api/taskall'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Pie },
  props: {
    options: Array,
    section: Array,
    idInfo: Object,
  },
  data() {
    //这里存放数据
    return {
      allNumber: 0,
      minInfo: {},
      getInfo: {
        //搜索框
        page_code: 1,
        page_num: 20,
      },
      innerVisible: false,
      textarea: '',
      pie: true,
      taskdialog: true,
      textList: [],
      redactContent: {
        state: 1,
        index: 1,
        info: {
          content: '',
          id: '',
        },
      },
      form: {
        name: '',
        users: [],
        pid: '',
        priority: '',
      },
      activeName: '1',
      formLabelWidth: '100px',
      chatInfo: [],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getTask(id, id2) {
      console.log(this.idInfo)
      this.getChatList(id)
      this.$parent.getEmployee()
      let info = await infoTaskA({
        id: id,
      })
      this.minInfo = info.data.data
      console.log(this.minInfo)
      this.del_time = this.minInfo.del_time
    },
    changePie() {
      this.pie = !this.pie
    },
    userClose() {
      this.$parent.openTask = false
    },
    //修改优先级
    async changePrio() {
      let form = {
        id: this.minInfo.id,
        priority: this.minInfo.priority,
      }
      let info = await taskmodelEdit(form)
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    //修改名称
    async changeName() {
      let form = {
        id: this.minInfo.id,
        name: this.minInfo.name,
      }
      let info = await taskmodelEdit(form)
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    //修改执行者，参与者
    async selectChange2() {
      let info = await taskmodelEdit({
        id: this.minInfo.id,
        participant: this.minInfo.participant,
        users: this.minInfo.users,
      })
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    async changeTime() {
      let form = {
        id: this.minInfo.id,
        del_time: this.minInfo.del_time,
      }
      let info = await checkTime(form)
      console.log('修改名称')
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    comChange() {
      let info = this.minInfo.percentage
      let number = 0
      console.log('info')
      console.log(info)
      info.forEach((item) => {
        number += item.percentage
      })
      this.allNumber = number
    },
    async taskChange() {
      let info = await taskmodelEdit({
        id: this.minInfo.id,
        percentage: this.minInfo.percentage,
      })
      if (this.judge(info)) {
        this.getTask(this.idInfo.id, this.idInfo.id2)
      }
    },
    taskCum() {
      if (this.allNumber > 100) {
        return
      }
      let info = this.minInfo.percentage
      let cNumber = 0
      let allNumber = this.allNumber
      info.forEach((item) => {
        if (item.percentage == 0) {
          cNumber++
        }
      })
      let numInfo = 100 - allNumber
      info.forEach((item) => {
        if (item.percentage == 0) {
          item.percentage = Math.floor(numInfo / cNumber)
          console.log(numInfo)
          console.log(cNumber)
          console.log(item.percentage)
        }
      })
    },
    reply(item) {
      // this.$parent.reply(item);
    },
    //  开始任务
    async openTask() {
      let form = { id: this.minInfo.id }
      let info = await taskmodelConfirmTask(form)
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    //任务完成
    async changeState() {
      let form = { id: this.minInfo.id }
      let info = await taskmodelAccomplish(form)
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    //修改负责人
    async changePrincipal() {
      let form = {
        id: this.minInfo.id,
        u_id: this.minInfo.u_id,
      }
      let info = await taskmodelEdit(form)
      this.judge(info)
      this.getTask(this.idInfo.id, this.idInfo.id2)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return false
      }
    },
    //删除
    redact(item, index, state) {
      this.redactContent = {
        state: state,
        index: index,
        info: {
          content: item.content,
          id: item.id,
        },
      }
      if (state == 1) {
        this.innerVisible = true
      } else {
        this.setEditChat(state)
      }
      console.log(item)
    },
    async setEditChat(state) {
      let info
      if (state == 1) {
        info = await editChat(this.redactContent.info)
      } else {
        info = await withdraw({ id: this.redactContent.info.id })
      }
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        let id = this.redactContent.info.id
        if (state == 1) {
          this.chatInfo[
            this.search(id)
          ].content = this.redactContent.info.content
          this.innerVisible = false
        } else {
          console.log(this.search(id))
          this.chatInfo.splice(this.search(id), 1)
        }
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
      }
    },
    //查找位置
    search(id) {
      for (let i = 0; i < this.chatInfo.length; i++) {
        if (this.chatInfo[i].id == id) {
          return i
        }
      }
    },
    //获取聊天记录
    async getChatList(id) {
      let info
      if (id) {
        this.getInfo.pid = id
        this.getInfo.page_code = 1
        info = await chatList(this.getInfo)
      } else {
        console.log()
        this.getInfo.pid = this.minInfo.id
        info = await chatList(this.getInfo)
      }
      this.chatInfo = info.data.data
      console.log(this.chatInfo)
      this.$nextTick(() => {
        if (this.$refs.Dynamic.scrollHeight) {
          this.$refs.Dynamic.scrollTop = this.$refs.Dynamic.scrollHeight
        }
      })
    },
    async getList() {
      this.getInfo.pid = this.minInfo.id
      this.getInfo.page_code++
      let info = await chatList(this.getInfo)
      this.chatInfo = info.data.data
    },
    // 发送消息
    async listen(item) {
      let info = await chatAddChat({
        pid: this.minInfo.id,
        content: this.textarea,
      })
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.textarea = ''
        this.getChatList()
        this.dialogFormVisible = false
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
      }
      console.log(info)
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getTask(this.idInfo.id, this.idInfo.id2)
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.home-index {
  height: calc(100% - 60px);
}
.image {
  margin: 0 8px 0 5px;
  padding: 2px;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eee;
  vertical-align: middle;
}
.circle {
  width: 100%;
  height: 200px;
  overflow: auto;
}
.circle-info {
  width: 40%;
  float: left;
  margin-right: 10px;
  margin-left: 15px;
}
.info-log {
  width: 100%;
  display: flex;
  border-top: 1px solid #e5e5e5;
}
.info-left {
  width: 30%;
  padding-left: 10px;
  padding-right: 20px;
  border-right: 1px solid #e5e5e5;
}
.info-right {
  flex: 1;
}
.info-pad >>> .el-dialog__body {
  padding: 10px !important;
}
.info-right-title {
  padding: 10px;
}
.over-task {
  width: 100%;
  max-height: 100px;
  overflow: auto;
}
.info-task-sli {
  width: 100%;
  max-height: 200px;
  margin-bottom: 10px;
  overflow: auto;
}
.sli-name {
  width: 40%;
  float: left;
  margin-right: 10px;
  margin-left: 15px;
}
/* .info-left >>> .el-input__inner,
.info-left >>> .el-select .el-input.is-focus .el-input__inner,
.info-left >>> .el-textarea__inner {
  border: none;
} */
.dynamic {
  height: 300px;
  overflow: auto;
  margin-bottom: 10px;
}
.dynamic-info {
  width: 100%;
  display: flex;
  padding-bottom: 5px;
  position: relative;
  border-radius: 5px;
  box-sizing: border-box;
  transition: all 0.2s;
}
.dynamic-left {
}
.dynamic-right {
  flex: 1;
}
.dynamic-right-top {
  width: 90%;
  display: flex;
  justify-content: space-between;
}
.dynamic-right-bottom {
  width: 90%;
  white-space: pre-wrap;
  text-indent: -4px;
}
.dynamic-time {
  color: #8c8c8c;
  font-size: 12px;
}
.dynamic-text-bottom {
  display: flex;
  justify-content: space-between;
}
.text-icon {
  font-size: 18px;
  cursor: pointer;
  color: #8c8c8c;
}
.text-icon:hover {
  color: #409eff;
}
.text-sty {
  color: #262626;
  font-size: 12px;
  cursor: pointer;
  display: none;
  margin-left: 10px;
}
.text-sty1 {
  font-size: 14px;
  color: black;
}
.dynamic-right-top:hover .text-sty {
  display: inline-block;
}
.text-sty:hover {
  color: #409eff;
}
.el-dialog__wrapper >>> {
  overflow: hidden;
}
.card-score {
  display: inline-block;
  color: #fff;
}
.card-score-title {
  color: #fff;
  text-indent: 10px;
}
.card-score-all {
  background: #e4d59e;
  border: 1px solid #e4d59e;
  border-radius: 5px;
  padding: 5px 0px;
}
.box-card {
  width: 300px;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.more {
  display: flex;
  justify-content: center;
  align-items: center;
}
.more-text {
  color: #409eff;
  cursor: pointer;
  user-select: none;
}

.dynamic-info:hover {
  top: -5px;
  margin-left: 5px;
  width: 99%;
  box-shadow: 1px 1px 5px rgb(38 38 38 / 30%);
  background: #ffff;
}
</style>
